<template>
  <div class="main-container">
    <div class="main-container-background shadow-lg p-3 mb-5 rounded" :style="`background-color:${urlImage}`">
      <div class="main-container-logo">
        <div class="J-profile-logo-content" style="width: 120px; height: 120px;">
          <div class="J-profile-logo">
            <img id="avatar" :src="dataUser.photo ? dataUser.photo.link : dataUser.image ? dataUser.image : imageUrl"
              v-if="!response.loading" loading="" />
            <span v-else class="J-profile-nonimage">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </span>
          </div>
          <UploadPartials title="Cargar archivo" accept="image/*" @data="savedData" button="camera" />
        </div>
      </div>
      <div class="container-title-company" v-for="client in dataCompany" v-bind:key="client">
        <h1 class="text-style">{{ client.nameCompany }}</h1>
        <div class="container-information-secondary">
          <div>
            <h6 class="text-style-secondary">{{ client.businessSector }}</h6>
          </div>
          &nbsp;
          <div>
            <h6 class="text-style-secondary">|</h6>
          </div>
          &nbsp;
          <div>
            <h6 class="text-style-secondary">{{ client.federalentityCompany }}</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="tarjetas-contadores">

      <!-- nuevo diseño -->
      <div class="newCardCount card shadow-lg">
        <div class="card-body" style="display: flex; flex-direction: column; justify-content: space-between;">
          <span class="card-title"
            style="font-size: 18px;font-family: Jost Variable, system-ui sans-serif;  align-content: center;">Total
            de Vacantes Creadas</span>
          <div style="display: flex; flex-direction: column; align-items: center;">
            <span style="font-size: 45px; font-weight: bold; font-family: roboto; color: #5f156e;">{{ vacantes }}</span>
          </div>
          <div style="align-self: flex-end;">
            <a href="/vacantes/empresa" class="card-link"
              style="font-family: roboto; color: #517aa8; text-decoration: none;">Ir a mis
              vacantes</a>
          </div>
        </div>
      </div>
      <div class="newCardCount card shadow-lg">
        <div class="card-body" style="display: flex; flex-direction: column; justify-content: space-between;">
          <span class="card-title"
            style="font-size: 18px;font-family: Jost Variable, system-ui sans-serif; align-content: center;">Mis
            Vacantes
            Vigentes</span>
          <div style="display: flex; flex-direction: column; align-items: center;">
            <span style="font-size: 45px; font-weight: bold; font-family: roboto; color: #5f156e;">
              {{ countVacantesCurrent }}
            </span>
          </div>
          <div style="align-self: flex-end;">
            <a href="/vacantes/empresa" class="card-link"
              style="font-family: roboto; color: #517aa8; text-decoration: none;">Ir a mis
              vacantes</a>
          </div>
        </div>
      </div>
      <div class="newCardCount card shadow-lg">
        <div class="card-body" style="display: flex; flex-direction: column; justify-content: space-between;">
          <span class="card-title"
            style="font-size: 18px; font-family: Jost Variable, system-ui sans-serif; align-content: center;">Mis
            Vacantes
            Expiradas</span>
          <div style="display: flex; flex-direction: column; align-items: center;">
            <span style="font-size: 45px; font-weight: bold; font-family: roboto; color: #5f156e;">
              {{ VacantesExpired }}
            </span>
          </div>
          <div style="align-self: flex-end;">
            <a href="/vacantes/empresa" class="card-link"
              style="font-family: roboto; color: #517aa8; text-decoration: none;">Ir a mis
              vacantes</a>
          </div>
        </div>
      </div>
      <div class="newCardCount card shadow-lg">
        <div class="card-body" style="display: flex; flex-direction: column; justify-content: space-between;">
          <span class="card-title"
            style="font-size: 18px; font-family: Jost Variable, system-ui sans-serif; align-content: center;">Total de
            Postulantes</span>
          <div style="display: flex; flex-direction: column; align-items: center;">
            <span style="font-size: 45px; font-weight: bold; font-family: roboto; color: #5f156e;">
              {{ postulantes }}</span>
          </div>
          <div style="align-self: flex-end;">
            <a href="/talento" class="card-link" style="font-family: roboto; color: #517aa8; text-decoration: none;">Ir
              a postulantes</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div aria-live="polite" aria-atomic="true" class="position-relative">
    <div class="toast-container top-0 end-0 p-3">
      <div class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true" v-for="client in request"
        v-bind:key="client">
        <div class="toast-header">
          <svg class="bd-placeholder-img rounded me-2" width="20" height="20" aria-hidden="true" focusable="false"
            preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
            <rect width="100%" height="100%" fill="#007aff"></rect>
          </svg>
          <strong class="me-auto">Mundo Universitario</strong>
          <small class="text-body-secondary">Hace 1 segundo</small>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Cerrar"></button>
        </div>
        <div class="toast-body">
          <p class="mb-2">La empresa <b>{{ client.informationClient.nameCompany }}</b>, desea agregarte como
            cliente
            para
            en
            conjunto manejar
            candidatos y vacantes</p>
          <div style="display: flex; gap: 4%; justify-content: center; align-items: center;">
            <button type="button" class="btn btn-primary btn-sm"
              v-on:click="changeCompanyClientStatus(client)">Aceptar</button>
            <button type="button" class="btn btn-danger btn-sm">Rechazar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as ClientService from "../services/client"
import UploadPartials from "@/components/partials/upload.partials.vue";
import { UserService } from "@/services";
import { CompanyService } from "@/services";

export default {
  data: () => {
    return {
      response: {
        loading: false,
      },
      urlImage: "#f5782d",
      imageUrl: "/images/profile.jpg",
      vacante: false,
      dataUser: {},
      dataCompany: {},
      idCompany: "",
      businessSector: "",
      nameCompany: "",
      federalentityCompany: "",
      postulantes: 0,
      vacantes: 0,
      countVacantesCurrent: 0,
      VacantesExpired: 0,
      VacantesSuspendedExpired: 0,
      idEmpresa: "",
      request: {},
      companyFilter: "",
    };
  },
  beforeMount() {
    this.dataUser = JSON.parse(localStorage.getItem("user") || null);
    this.idCompany = this.dataUser._id;
    let { _id: companyFilter } = this.dataUser.company;
    this.companyFilter = companyFilter
    this.getInformationCompany({ _id: this.idCompany });
    this.PendingClientRequests(companyFilter);
    this.idEmpresa = this.dataUser.company._id;
    this.getTotalVacantes({ _id: this.idEmpresa });
    this.getTotalPostulantes({ _id: this.idEmpresa });
  },
  methods: {
    async getInformationCompany(id) {
      try {
        const response = await CompanyService.getCompanyUser(id);
        this.dataCompany = response.data.data
      } catch (error) {
        console.error('Error en la llamada a la API:', error.message);
      }
    },
    async savedData(file) {
      try {
        this.response.loading = true;
        const resp = await UserService.savedImage({
          file: file,
          type: "company",
        });
        const { data } = resp.data;
        const user = JSON.parse(localStorage.getItem("user") || null);
        user.photo = data;
        this.dataUser = user;
        this.response.loading = false;
        localStorage.setItem("user", JSON.stringify(user));
      } catch ($e) {
        this.response.loading = false;
      }
    },

    async PendingClientRequests(filter) {
      let resp = await ClientService.getPendingClientRequests({ _id_client: filter })
      this.request = resp.data.data
    },
    async changeCompanyClientStatus(item) {
      console.log(item)

      let resp = await ClientService.changeCompanyClientStatus(item)
      this.$swal({
        position: "top-center",
        icon: "success",
        text: resp.data.message,
        showConfirmButton: false,
        timer: 3000,
      });
      this.PendingClientRequests(this.companyFilter);
    },
    getTotalVacantes(id) {
      CompanyService.getCountVacantesEmpresa(id).then((response) => {
        this.vacantes = response.data.data;
        this.countVacantesCurrent = response.data.countVacantesCurrent;
        this.VacantesExpired = response.data.VacantesExpired;
        this.VacantesSuspendedExpired = response.data.VacantesSuspendedExpired;
      })
    },
    getTotalPostulantes(id) {
      CompanyService.getCountPostulantesEmpresa(id).then((response) => {
        this.postulantes = response.data.data;
      });
    },
  },
  components: {
    UploadPartials,
  },
};
</script>

<style land="scss" scoped>
@import "../styles/profilecompany.scss";
</style>
